import { useContext } from "react";
import { Actions } from "../stateManagement/actions";
import { useStateDispatch } from "./useStateDispatch";
import { SaveQueryModalPayloadContext } from "../context/defs";

export function useSaveQuery() {
  const state = useContext(SaveQueryModalPayloadContext);

  const dispatch = useStateDispatch();

  const openView = () => {
    dispatch({
      type: Actions.SHOW_CREATE_QUERY_VIEW,
    });
  };

  const closeView = () => {
    dispatch({
      type: Actions.CLOSE_CREATE_QUERY_VIEW,
    });
  };

  return {
    ...state,
    openView,
    closeView,
  };
}

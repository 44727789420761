import { CircularProgress, FormGroup } from "@mui/material";
import {
  CancelButton,
  ComingSoon,
  ConditionEntryRoot,
  ConditionSelector,
  ConditionsRoot,
  ConditionThresholdInput,
  Content,
  Footer,
  Form,
  FormGroupLabel,
  FormGroupRoot,
  FormInput,
  FormText,
  Root,
  Selector,
  SubmitButton,
  Title,
} from "../../../../../shared-component/Dialog/styled";
import {
  Checkbox,
  FormGroupContainer,
  FormItem,
  InputsContainer,
} from "./styled";
import { useEffect, useMemo, useState } from "react";
import ConditionComponent from "../../../../../../utils/ConditionComponent";
import { SaveQueryActionTypes } from "../../../../../../app/asset-management/config";
import { formValidator } from "./formValidator";
import { InvalidPayloadError } from "../../../../../../app/errors/form-errors/InvalidPayloadError";
import {
  showErrorAlert,
  showSuccessfulOperationAlert,
} from "../../../../../../app/alert/showAlert";
import { useAuth0 } from "@auth0/auth0-react";

const WebhookMethods = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

const ActionType = {
  SLACK: "slack",
  WEBHOOK: "webhook",
  EMAIL: "email",
  JIRA: "JIRA",
};

const slackNotificationMethodPayload = {
  channel: "",
  message: "",
};

const emailNotificationMethodPayload = {
  to: "",
  subject: "",
  body: "",
};

const webhookNotificationMethodPayload = {
  url: "",
  method: "",
  body: "",
  headers: "",
};

const jiraIssuesNotificationMethodPayload = {
  projectKey: "",
  issueType: "",
  summary: "",
};

const methodPayloadAdapter = {
  [ActionType.EMAIL]: emailNotificationMethodPayload,
  [ActionType.JIRA]: jiraIssuesNotificationMethodPayload,
  [ActionType.SLACK]: slackNotificationMethodPayload,
  [ActionType.WEBHOOK]: webhookNotificationMethodPayload,
};

const conditionOptions = [
  {
    value: "greater-than",
    label: "Greater Than",
  },
  {
    value: "less-than",
    label: "Less Than",
  },
  {
    value: "equals",
    label: "Equals",
  },
  {
    value: "any-change",
    label: "Any Change",
  },
  {
    value: "entities-added",
    label: "Entities Added",
  },
  {
    value: "entities-removed",
    label: "Entities Removed",
  },
];

const emptyThresholdConditions = [
  "any-change",
  "entities-added",
  "entities-removed",
];

export const SavedQueryAlert = ({
  data = {},
  onClose,
  meta = {},
  actionType = SaveQueryActionTypes.CREATE,
  payloadTransformer,
  ...props
}) => {
  const emptyFormState = {
    name: "",
    condition: "",
    description: "",
    threshold: 0,
    notificationMethods: [],
    ruleUuid: "",
  };

  const { user } = useAuth0();
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(emptyFormState);

  const conditionThresholdDisabled = useMemo(() => {
    return emptyThresholdConditions.some((cond) => cond === form.condition);
  }, [form]);

  const checkFieldHasError = (field) => {
    return errors.find((err) => err.field === field);
  };

  const label = actionType === SaveQueryActionTypes.CREATE ? "Save" : "Edit";

  const {
    hasEmailNotification,
    hasJiraNotification,
    hasSlackNotification,
    hasWebhookIntegration,
  } = useMemo(() => {
    const hasSlackNotification = form.notificationMethods.some(
      (method) => method.type === ActionType.SLACK
    );
    const hasEmailNotification = form.notificationMethods.some(
      (method) => method.type === ActionType.EMAIL
    );
    const hasWebhookIntegration = form.notificationMethods.some(
      (method) => method.type === ActionType.WEBHOOK
    );
    const hasJiraNotification = form.notificationMethods.some(
      (method) => method.type === ActionType.JIRA
    );

    const status = {
      hasSlackNotification,
      hasEmailNotification,
      hasWebhookIntegration,
      hasJiraNotification,
    };
    return status;
  }, [form]);

  const conditionPayload = useMemo(() => {
    return conditionOptions.find((cond) => form.condition === cond.value);
  }, [form.condition]);

  const onChangeCondition = ({ value: condition }) => {
    setForm((form) => ({ ...form, condition }));
  };

  const onChangeConditionThreshold = (ev) => {
    const value = ev.target.value;
    setForm((form) => ({ ...form, threshold: value }));
  };

  const onChangeDesc = (ev) => {
    const value = ev.target.value;
    setForm((form) => ({ ...form, description: value }));
  };

  const onChangeName = (ev) => {
    const value = ev.target.value;
    setForm((form) => ({ ...form, name: value }));
  };

  const checkHasNotificationMethod = (validateMethod) =>
    form.notificationMethods.some((method) => method.type === validateMethod);

  const addNotificationMethod = (method) => {
    const payload = methodPayloadAdapter[method];
    const newMethod = {
      type: method,
      payload,
    };
    const notificationMethods = [...form.notificationMethods, newMethod];
    setForm((form) => ({ ...form, notificationMethods }));
  };

  const removeNotificationMethod = (method) => {
    const notificationMethods = form.notificationMethods.filter(
      (notificationMethod) => notificationMethod.type !== method
    );
    setForm((form) => ({ ...form, notificationMethods }));
  };

  const getNotificationMethodPayload = (method) => {
    return form.notificationMethods.find(
      (notificationMethod) => notificationMethod.type === method
    )?.payload;
  };

  const onChangeNotificationMethod = (method) => {
    if (checkHasNotificationMethod(method)) {
      removeNotificationMethod(method);
    } else {
      addNotificationMethod(method);
    }
  };

  const setNotificationMethodPayload = (method, payload) => {
    const notificationMethods = form.notificationMethods.map(
      (notificationMethod) => {
        if (method === notificationMethod) {
          return { ...notificationMethod, payload };
        }
        return notificationMethod;
      }
    );
    setForm((form) => ({ ...form, notificationMethods }));
  };

  const handleOnChangeNotificationMethodPayload = (method, field, ev) => {
    const methodPayload = getNotificationMethodPayload(method);
    methodPayload[field] = ev.target.value;
    setNotificationMethodPayload(method, methodPayload);
  };

  const resetForm = () => {
    setForm(emptyFormState);
    setErrors([]);
  };

  const handleOnSave = async () => {
    try {
      const errors = formValidator(form);
      if (errors.length) {
        throw new InvalidPayloadError("Invalid form payload", errors);
      }
      const transformedPayload = payloadTransformer?.(form, data, user);
      const payload = transformedPayload ?? form;
      if (!payload.table_name) payload.table_name = data.table_name;
      await props.onSave?.(payload);
      resetForm();
      onClose();
      const message =
        actionType === SaveQueryActionTypes.CREATE
          ? "Action created successfully"
          : "Action updated successfully";

      showSuccessfulOperationAlert(message);
    } catch (error) {
      if (error.errors) {
        setErrors(error.errors);
        showErrorAlert(error.message);
      } else {
        const defaultErrorMessage = "Operation Failed!";
        showErrorAlert(defaultErrorMessage);
      }
    }
  };

  const handleOnClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (data) {
      const initialFormState = {
        ruleUuid: data.rule_uuid,
        name: data?.name ?? "",
        condition: data?.condition ?? "",
        description: data?.description ?? "",
        threshold: data?.threshold ?? 0,
        notificationMethods: actionsToNotificationMethodsAdapter(data?.actions),
      };
      setForm(initialFormState);
    }
  }, [data]);

  return (
    <Root {...props} onClose={handleOnClose}>
      <Title>Set Up New Alert</Title>
      <Content className='scrollable'>
        <Form>
          <FormGroupRoot>
            <FormGroupLabel error={checkFieldHasError("name")}>
              Name
            </FormGroupLabel>
            <FormInput
              required
              onChange={onChangeName}
              value={form.name}
              error={checkFieldHasError("name")}
            />
          </FormGroupRoot>
          <FormGroupRoot>
            <FormGroupLabel>Description</FormGroupLabel>
            <FormInput
              onChange={onChangeDesc}
              multiline
              rows={3}
              value={form.description}
            />
          </FormGroupRoot>

          <ConditionsRoot>
            <FormGroupLabel error={checkFieldHasError("condition")}>
              Conditions
            </FormGroupLabel>
            <ConditionEntryRoot>
              <ConditionSelector
                value={conditionPayload}
                onChange={onChangeCondition}
                error={checkFieldHasError("condition")}
                placeholder='Select Condition'
                options={conditionOptions}
              />
              <ConditionThresholdInput
                value={form.threshold}
                onChange={onChangeConditionThreshold}
                disabled={conditionThresholdDisabled}
                error={checkFieldHasError("threshold")}
                placeholder='Enter Threshold value'
              />
            </ConditionEntryRoot>
          </ConditionsRoot>

          <FormGroup>
            <FormGroupLabel error={checkFieldHasError("notificationMethods")}>
              Notification Methods (Select a least one)
            </FormGroupLabel>

            <FormGroupContainer>
              <FormItem
                disabled
                control={
                  <Checkbox
                    size='small'
                    checked={hasSlackNotification}
                    onChange={() =>
                      onChangeNotificationMethod(ActionType.SLACK)
                    }
                  />
                }
                label={
                  <FormText>
                    Slack <ComingSoon />
                  </FormText>
                }
              />
              <ConditionComponent condition={hasSlackNotification}>
                <InputsContainer>
                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.SLACK)?.channel
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.SLACK,
                        "channel",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Enter Channel name'
                  />
                  <FormInput
                    multiline
                    rows={3}
                    value={
                      getNotificationMethodPayload(ActionType.SLACK)?.message
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.SLACK,
                        "message",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Enter Message'
                  />
                </InputsContainer>
              </ConditionComponent>
            </FormGroupContainer>
            <FormGroupContainer>
              <FormItem
                control={
                  <Checkbox
                    size='small'
                    checked={hasEmailNotification}
                    onChange={() =>
                      onChangeNotificationMethod(ActionType.EMAIL)
                    }
                  />
                }
                label={<FormText>Email</FormText>}
              />
              <ConditionComponent condition={hasEmailNotification}>
                <InputsContainer>
                  <FormInput
                    value={getNotificationMethodPayload(ActionType.EMAIL)?.to}
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.EMAIL,
                        "to",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Enter comma separated addresses'
                  />
                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.EMAIL)?.subject
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.EMAIL,
                        "subject",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Email Subject'
                  />
                  <FormInput
                    multiline
                    rows={4}
                    value={getNotificationMethodPayload(ActionType.EMAIL)?.body}
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.EMAIL,
                        "body",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Enter Message'
                  />
                </InputsContainer>
              </ConditionComponent>
            </FormGroupContainer>
            <FormGroupContainer>
              <FormItem
                disabled
                control={
                  <Checkbox
                    size='small'
                    checked={hasWebhookIntegration}
                    onChange={() =>
                      onChangeNotificationMethod(ActionType.WEBHOOK)
                    }
                  />
                }
                label={
                  <FormText>
                    Webhook <ComingSoon />
                  </FormText>
                }
              />
              <ConditionComponent condition={hasWebhookIntegration}>
                <InputsContainer>
                  <FormInput
                    value={getNotificationMethodPayload(ActionType.EMAIL)?.url}
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.WEBHOOK,
                        "url",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Url'
                  />
                  <Selector options={WebhookMethods} />

                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.EMAIL)?.headers
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.WEBHOOK,
                        "headers",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Headers (JSON Format)'
                    multiline
                    rows={4}
                  />
                  <FormInput
                    value={getNotificationMethodPayload(ActionType.EMAIL)?.body}
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.WEBHOOK,
                        "body",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Body (JSON Format)'
                    multiline
                    rows={4}
                  />
                </InputsContainer>
              </ConditionComponent>
            </FormGroupContainer>
            <FormGroupContainer>
              <FormItem
                disabled
                control={
                  <Checkbox
                    size='small'
                    checked={hasJiraNotification}
                    onChange={() => onChangeNotificationMethod(ActionType.JIRA)}
                  />
                }
                label={
                  <FormText>
                    Open Jira issue <ComingSoon />
                  </FormText>
                }
              />
              <ConditionComponent condition={hasJiraNotification}>
                <InputsContainer>
                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.JIRA)?.projectKey
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.JIRA,
                        "projectKey",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Jira Project Key'
                  />

                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.JIRA)?.issueType
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.JIRA,
                        "issueType",
                        ev
                      )
                    }
                    fullWidth
                    type='text'
                    placeholder='Issue Type'
                  />

                  <FormInput
                    value={
                      getNotificationMethodPayload(ActionType.JIRA)?.summary
                    }
                    onChange={(ev) =>
                      handleOnChangeNotificationMethodPayload(
                        ActionType.JIRA,
                        "summary",
                        ev
                      )
                    }
                    fullWidth
                    multiline
                    rows={4}
                    type='text'
                    placeholder='Issue Summary'
                  />
                </InputsContainer>
              </ConditionComponent>
            </FormGroupContainer>
          </FormGroup>
        </Form>
      </Content>
      <Footer>
        <CancelButton onClick={handleOnClose}>Cancel</CancelButton>
        <SubmitButton onClick={handleOnSave}>
          <ConditionComponent
            falsyNode={
              <CircularProgress
                sx={{ color: "#00ffb2" }}
                variant='indeterminate'
                size={"20px"}
              />
            }
            condition={!meta?.isLoading}
          >
            {label}
          </ConditionComponent>
        </SubmitButton>
      </Footer>
    </Root>
  );
};

function actionsToNotificationMethodsAdapter(actions) {
  if (!actions || !actions.length) return [];
  return actions.map(({ action_type, ..._payload }) => {
    const payload = _payload;
    if (action_type === ActionType.EMAIL) {
      payload.to = _payload.to.join(",");
    }
    return {
      type: action_type,
      payload,
    };
  });
}

const {
  createColumnsPayload,
} = require("../../../ui/shared-component/AssetTable/component/SavePopover/utils/createColumnsPayload");
const {
  createFiltersPayload,
} = require("../../../ui/shared-component/AssetTable/component/SavePopover/utils/createFiltersPayload");
const {
  createGroupPayload,
} = require("../../../ui/shared-component/AssetTable/component/SavePopover/utils/createGroupPayload");

export const transformRunSavedQueryEditActionPayload = (payload) => {
  const filters = createFiltersPayload(payload.filters);
  const columns = createColumnsPayload(payload.columns);
  const group = createGroupPayload(payload.group);
  payload.filters = filters;
  payload.columns = columns;
  payload.group = group;
  payload.search = payload.searchKeyword;
  return payload;
};

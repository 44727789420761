import { useGlobalState } from "./useGlobalState";
import { useDispatch } from "./useDispatch";
import { Actions } from "../stateManagement/actions";
import { CMSTService } from "../../../../services/CMSTService";
import { useFetchData } from "./useFetchdata";
import {
  showErrorAlert,
  showSuccessfulOperationAlert,
} from "../../../../app/alert/showAlert";

export function useDeleteAction() {
  const { showDeleteActionView, deleteActionPayload } = useGlobalState();
  const dispatch = useDispatch();
  const fetchData = useFetchData();

  const close = () => {
    dispatch({
      type: Actions.CLOSE_DELETE_ACTION,
    });
  };

  const open = (payload) => {
    dispatch({
      type: Actions.OPEN_DELETE_ACTION,
      payload,
    });
  };

  const deleteAction = async () => {
    try {
      dispatch({
        type: Actions.DELETE_ACTION_PENDING,
      });

      await CMSTService.getInstance().deleteAction({
        ruleUuid: deleteActionPayload.data.rule_uuid,
        table_name: deleteActionPayload.data.table_name,
      });
      dispatch({
        type: Actions.DELETE_ACTION_SUCCESS,
      });
      showSuccessfulOperationAlert("Action deleted successfully");
      await fetchData();
    } catch (error) {
      dispatch({
        type: Actions.DELETE_ACTION_ERROR,
        payload: error.message,
      });
      showErrorAlert("Failed to delete Action");
      throw error;
    }
  };

  return {
    openState: showDeleteActionView,
    payload: deleteActionPayload,
    close,
    open,
    deleteAction,
  };
}

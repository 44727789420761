import { Typography } from "@mui/material";
import { PageHeader } from "../../../shared-component/PageLayout";
import { PageRoot } from "./component/styled";
import { PageNavigationButton } from "../../../shared-component/Navigation";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getIssueDetail } from "../../../../state-management/CMST/thunks/getIssueDetaiil";
import { useIssueDetail } from "../hooks/useIssueDetail";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { Content } from "./component/Content";
import { LoadingSection } from "../../../../components/LayoutFillers";
import { createAssetInfo } from "../utils/createAssetInfo";
import { ErrorSection } from "../../../../components/LayoutFillers/ErrorSection";

const PageEntry = (props) => {
  const { goBack } = useAppNavigator();
  const dispatch = useDispatch();
  const params = useParams();

  const { id, projectId } = params;

  const { data, isLoading, error } = useIssueDetail();

  const dataExists = !!Object.keys(data).length;

  const profile = useMemo(() => {
    if (projectId && dataExists) {
      const issueProfile = createAssetInfo(data ?? {});

      issueProfile.projectName = projectId;
      return issueProfile;
    }
    return {};
  }, [data, projectId, dataExists]);

  const profileExists = !!Object.keys(profile).length;

  const showContent = !isLoading && !error;

  const fetchIssue = () => {
    dispatch(getIssueDetail({ id }));
  };

  const heading = useMemo(() => {
    return `${projectId}/${id}`;
  }, [projectId, id]);

  useEffect(() => {
    fetchIssue();
  }, [id]);

  return (
    <PageRoot>
      <PageHeader>
        <PageNavigationButton onClick={goBack} label={"Back to Tickets"} />
        <Typography variant='h1'>{heading}</Typography>
      </PageHeader>
      <ConditionComponent condition={isLoading}>
        <LoadingSection />
      </ConditionComponent>
      <ConditionComponent
        condition={showContent && !isLoading && !error && profileExists}
      >
        <Content data={profile} />
      </ConditionComponent>
      <ConditionComponent condition={!isLoading && error}>
        <ErrorSection errorMessage={"Failed to fetch issue"} />
      </ConditionComponent>
    </PageRoot>
  );
};

export default PageEntry;

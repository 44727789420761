import { useMemo } from "react";
import { usePrimaryTableColumnState } from "./usePrimaryTableColumnState";
import { usePrimaryTableState } from "./usePrimaryTableState";
import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";
import { DataGroupsSources } from "../../../../components/Grouping/data";
import { useGroupingTableState } from "./useGroupingTableState";
import { useIsGrouping } from "./useIsGrouping";

export function useSearchResultsFieldColumns() {
  const primaryTableCols = usePrimaryTableColumnState()[0];
  const { meta } = usePrimaryTableState();
  const { meta: groupingMeta } = useGroupingTableState();
  const isGrouping = useIsGrouping();

  return useMemo(() => {
    let cols = meta?.matched_keys ?? [];
    if (isGrouping) {
      cols = groupingMeta?.matched_keys ?? [];
    }
    if (cols && cols?.length) {
      return createKeysToColumnTransformer(cols, primaryTableCols);
    }
    return [];
  }, [meta, primaryTableCols, isGrouping, groupingMeta]);
}

function getColumnParts(col) {
  let source = "",
    value = "";
  if (!col.startsWith("adapters")) source = DataGroupsSources.PLATFORM;
  else {
    const asArr = col.split(".");
    source = asArr[1];
  }
  if (source === DataGroupsSources.PLATFORM) {
    if (col.startsWith("common_fields")) {
      value = col;
    } else {
      const asArr = col.split(".");
      value = asArr[1] ?? col;
    }
  } else {
    value = col.replace(`adapters.${source}.`, "");
  }

  return {
    source,
    value,
  };
}

function createKeysToColumnTransformer(keys, columns) {
  const selectedCols = new Set(columns.map((col) => col.id));
  const checkIsSelected = (optionId) => {
    return selectedCols.has(optionId);
  };

  return keys.map((key) => {
    const { source, value } = getColumnParts(key);
    const option = {
      value,
      label: value,
      source,
    };
    const idContext =
      source === DataGroupsSources.PLATFORM ? "options" : `${source}-options`;
    option.id = createOptionId(option, idContext);
    option.checked = checkIsSelected(option.id);
    if (option.source === DataGroupsSources.PLATFORM)
      console.log({ option, selectedCols });
    return option;
  });
}

import { useEffect, useReducer } from "react";
import {
  AssetInventoryDataContext,
  AssetInventoryDispatchContext,
  AssetInventoryGroupingTableStateContext,
  AssetInventoryPageMetaContext,
  AssetInventoryPrimaryTableStateContext,
  AssetsTableMethodsContext,
} from "./contexts";
import { useAuth0 } from "@auth0/auth0-react";
import { getInventoryAssetsByFilters } from "../../../../utils/api";
import { InventoryFilterUrlParser } from "../../../../ui/pages/inventory/utils/filterToUrlModule";
import { useHistory } from "react-router-dom";
import {
  checkIsRunSavedQueryView,
  getPageInitialEncodedViewConfig,
  getRunSavedQueryViewParams,
} from "../utils/getPageInitialEncodedViewConfig";
import { decodeEncodedFiltersFromUrl } from "../utils/decodeEncodedFiltersFromUrl";
import { Reducer } from "../state-management/reducer";
import { InitialState } from "../state-management/initialState";
import { ActionType } from "../state-management/actions";
import { createAssetInfoSubPageUrl } from "../../../../utils/routing/createAssetInfoUrl";
import { AppRoutingConfig } from "../../../../utils/routing/RouteConfig";
import { useDispatch } from "react-redux";
import { getAssetOptions } from "../../../../state-management/Inventory/thunks/getAssetOptions";
import { AssetInventoryTableFixedColumns } from "../../../../core/asset-inventory/data";
import { InventoryService } from "../../../../services/inventoryService";
import { SystemInsights } from "../../../../utils/logger/SystemInsights";
import { createSavedQueryViewPayload } from "../utils/createSavedQueryViewPayload";
import { useSyncPayloadWithUrl } from "../../../../hooks/useSyncPayloadWithUrl";
import { InventorySaveQueryPayloadAdapter } from "../../../../app/asset-management/inventory/adapters/SaveQueryPayloadAdapter";

export const AssetsTableContextProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  //
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const globalStateDispatch = useDispatch();
  const syncPayloadWithUrl = useSyncPayloadWithUrl();

  //

  const history = useHistory();

  const fetchGroupingTableData = async (_token, payload, options) => {
    try {
      //transform this to match with grouping interface for syncPayloadWithUrl sanitizeGrouping method
      const transformedGroupingPayload = {
        group: payload?.group?.group,
        option: {
          value: payload?.group?.value,
          label: payload?.group?.value,
        },
      };
      const encodingPayload = { ...payload };
      encodingPayload.grouping = transformedGroupingPayload;

      const syncPayload = options?.syncPayload ?? true;
      if (syncPayload) {
        syncPayloadWithUrl(encodingPayload);
      }
      dispatch({
        type: ActionType.FETCH_GROUPING_TABLE_DATA,
        payload: {},
      });
      let token = _token;
      if (!token) {
        token = await getAccessTokenSilently();
      }
      const data = await getInventoryAssetsByFilters(token, payload);
      dispatch({
        type: ActionType.FETCH_GROUPING_DATA_SUCCESSFUL,
        payload: data,
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: ActionType.FETCH_GROUPING_DATA_FAILED,
        payload: error,
      });
    }
  };

  const fetchPrimaryTableData = async (_token, payload, options) => {
    try {
      const syncPayload = options?.syncPayload ?? true;
      console.log("only fetchPrimaryTableData", { syncPayload, payload });
      if (syncPayload) {
        syncPayloadWithUrl(payload);
      }
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA,
        payload: {},
      });
      let token = _token;
      if (!token) {
        token = await getAccessTokenSilently();
      }
      const data = await getInventoryAssetsByFilters(token, payload);
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ActionType.FETCH_PRIMARY_TABLE_DATA_FAILED,
        payload: {
          error,
        },
      });
    }
  };

  const createSharedViewPayload = (encoding) => {
    SystemInsights.log("createSharedViewPayload >> START", encoding);
    const config = InventoryFilterUrlParser.decodeUrlToFilters(encoding);

    let primaryTableColumns = AssetInventoryTableFixedColumns;

    const filters = decodeEncodedFiltersFromUrl(config.filters);

    console.log({ config, filters });

    const currentGroupingValue = config?.group;
    const grouping = [];
    if (currentGroupingValue) {
      grouping.push(currentGroupingValue.group);
    }

    if (config.primaryTableColumns?.length) {
      primaryTableColumns = config.primaryTableColumns;
    }

    const payload = {
      filters,
      grouping,
      currentGroupingValue,
      searchKeyword: config.searchKeyword ?? "",
      primaryTableColumns,
      paginationConfig: {
        pageIndex: config?.page,
        pageSize: config?.limit,
      },
    };

    console.log("only >", primaryTableColumns);

    SystemInsights.log("createSharedViewPayload >> END", payload);

    return payload;
  };

  const fetchInitialInventoryData = async () => {
    try {
      dispatch({
        type: ActionType.INIT_PAGE,
        payload: {},
      });

      const initialEncodedConfig = getPageInitialEncodedViewConfig();
      const token = await getAccessTokenSilently();
      const response = await globalStateDispatch(getAssetOptions());
      const assetsSchema = response.payload.data;
      const isSavedQuery = checkIsRunSavedQueryView();
      const isSharedView = !!initialEncodedConfig || isSavedQuery;
      if (isSharedView) {
        let initialPagePayload;
        if (isSavedQuery) {
          // fetch query
          const { query } = getRunSavedQueryViewParams();
          if (!query) throw new Error("Error getting view");

          const savedQuery = await InventoryService.getInstance().getQuery(
            query
          );

          initialPagePayload = createSavedQueryViewPayload(
            savedQuery,
            assetsSchema,
            InventorySaveQueryPayloadAdapter.instance
          );
        }
        if (initialEncodedConfig) {
          const initialEncodedPayload =
            createSharedViewPayload(initialEncodedConfig);
          initialPagePayload = {
            ...initialPagePayload,
            ...initialEncodedPayload,
          };
        }

        if (!initialPagePayload) throw new Error("Error Loading view"); // edge case

        initialPagePayload.assetOptionsMap = assetsSchema;

        dispatch({
          type: ActionType.INIT_PAGE_SUCCESS,
          payload: initialPagePayload,
        });
        const isGrouping = !!initialPagePayload.grouping.length;
        const {
          paginationConfig: { pageIndex, pageSize },
          filters,
          currentGroupingValue,
          searchKeyword,
        } = initialPagePayload;
        if (isGrouping) {
          const payload = {
            page: pageIndex,
            limit: pageSize,
            filters,
            group: currentGroupingValue,
            searchKeyword,
          };
          await fetchGroupingTableData(token, payload, { syncPayload: false });
        } else {
          const payload = {
            page: pageIndex,
            limit: pageSize,
            filters,
            group: [],
            searchKeyword,
          };
          await fetchPrimaryTableData(token, payload, { syncPayload: false });
        }
      } else {
        dispatch({
          type: ActionType.INIT_PAGE_SUCCESS,
          payload: {
            assetOptionsMap: assetsSchema,
            primaryTableColumns: AssetInventoryTableFixedColumns,
          },
        });
        const payload = {
          page: state.primaryTableMeta.paginationConfig.pageIndex,
          limit: state.primaryTableMeta.paginationConfig.pageSize,
          filters: state.filters,
          group: state.currentGroupingValue,
          searchKeyword: state.searchKeyword.new,
        };
        await fetchPrimaryTableData(token, payload, { syncPayload: false });
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log("-----END-----");
    }
  };

  const redirectSubPage = (data) => {
    const path = createAssetInfoSubPageUrl(
      data.id,
      AppRoutingConfig.inventory.AssetInfo.GENERAL
    );
    const previousUrl = window.location.pathname;
    history.push(path, { previousUrl });
  };

  const openAssetDrawer = (payload) => {
    dispatch({
      type: ActionType.OPEN_ASSET_DETAIL_VISIBLE,
      payload: payload,
    });
    redirectSubPage(payload);
  };

  const closeAssetDrawer = () => {
    dispatch({
      type: ActionType.CLOSE_ASSET_DETAIL_VISIBLE,
      payload: null,
    });
    history.push("/inventory");
  };

  const pageData = {
    filters: state.filters,
    primaryTableColumns: state.primaryTableColumns,
    grouping: state.grouping,
    currentGroupingValue: state.currentGroupingValue,
    assetOptionsMap: state.assetOptionsMap,
    searchKeyword: state.searchKeyword,
    assetDetailVisible: state.assetDetailVisible,
    selectedAssetForDetails: state.selectedAssetForDetails,
    saveQueryPayload: state.saveQueryPayload,
  };

  useEffect(() => {
    fetchInitialInventoryData();
  }, []);

  return (
    <AssetsTableMethodsContext.Provider
      value={{
        openAssetDrawer,
        closeAssetDrawer,
        syncPayloadWithUrl,
        //
        fetchPrimaryTableData,
        fetchGroupingTableData,
      }}
    >
      <AssetInventoryDispatchContext.Provider value={dispatch}>
        <AssetInventoryPageMetaContext.Provider value={state.pageMeta}>
          <AssetInventoryPrimaryTableStateContext.Provider
            value={state.primaryTableMeta}
          >
            <AssetInventoryGroupingTableStateContext.Provider
              value={state.groupingTableMeta}
            >
              <AssetInventoryDataContext.Provider value={pageData}>
                {children}
              </AssetInventoryDataContext.Provider>
            </AssetInventoryGroupingTableStateContext.Provider>
          </AssetInventoryPrimaryTableStateContext.Provider>
        </AssetInventoryPageMetaContext.Provider>
      </AssetInventoryDispatchContext.Provider>
    </AssetsTableMethodsContext.Provider>
  );
};

import { createContext } from "react";
import { AssetManagementConfig } from "../../../../app/asset-management/config";
import { doNothing } from "../../../../utils/helperMethods/doNothing";
import { InitialState } from "../stateManagement/initialState";

export const PrimaryTableContext = createContext({
  data: [],
  meta: {},
  isLoading: false,
  error: false,
  errorPayload: null,
  paginationConfig: {
    pageSize: AssetManagementConfig.DEFAULT_PAGE_SIZE,
    pageIndex: 0,
  },
});
export const GroupingTableContext = createContext();
export const AssetTableStateContext = createContext({
  columns: [],
  grouping: [],
  currentGroupingOption: null,
  currentGroupingOptions: [],
  schema: {},
  searchKeyword: "",
  filters: [],
  table: "",
});
export const MethodsContext = createContext({
  dispatch: doNothing,
  fetchData: doNothing,
  fetchPrimaryTableData: doNothing,
  fetchGroupingTableData: doNothing,
  onClickAsset: doNothing,
});

export const SaveQueryPayloadContext = createContext(
  InitialState.saveQueryPayload
);

export const SaveQueryModalPayloadContext = createContext(
  InitialState.saveQueryModalPayload
);

export const TablePropsContext = createContext({
  saveQueryActions: {
    save: doNothing,
    edit: doNothing,
  },
  onClickAsset: doNothing,
});

import { DataFilterConjunctions } from "../../../../core/asset-inventory/filterConjunctions";
import { DataFilterOperators } from "../../../../core/asset-inventory/filterOperators";
import { generateRandomStr } from "../../../../utils/helperMethods/generateId";

function getOptionByValue(value, sourceOptions) {
  return Object.values(sourceOptions).find((val) => val.value === value.value);
}

function decodeFilter({ operator, conjunction, field, values }, index) {
  const filter = {
    operator: getOptionByValue(operator, DataFilterOperators),
    field,
    values,
    id: generateRandomStr(),
  };
  filter.conjunction = getOptionByValue(conjunction, DataFilterConjunctions);
  return filter;
}

export function decodeEncodedFiltersFromUrl(filters) {
  return filters.map(decodeFilter);
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMSTService } from "../../../services/CMSTService";

export const getIssuesSummary = createAsyncThunk(
  "issues-summary",
  async (payload, thunkApi) => {
    try {
      //
      const [{ data: options }, { data: summary }] = await Promise.all([
        CMSTService.getInstance().getAllIssuesOptions(payload),
        CMSTService.getInstance().getIssuesSummary(payload),
      ]);
      return thunkApi.fulfillWithValue({
        options,
        summary,
      });
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

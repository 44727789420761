import { useGlobalState } from "./useGlobalState";
import { useDispatch } from "./useDispatch";
import { Actions } from "../stateManagement/actions";
import { CMSTService } from "../../../../services/CMSTService";
import { showAlert } from "../../../../app/alert/showAlert";

export function useEditAction() {
  const { showEditActionView, editActionModalPayload } = useGlobalState();
  const dispatch = useDispatch();

  const close = () => {
    dispatch({
      type: Actions.CLOSE_EDIT_ACTION_MODAL,
    });
  };

  const open = (payload) => {
    dispatch({
      type: Actions.OPEN_EDIT_ACTION_MODAL,
      payload,
    });
  };

  const editAction = async (payload) => {
    try {
      dispatch({
        type: Actions.EDIT_ACTION_PENDING,
      });
      await CMSTService.getInstance().editAction(payload);
      dispatch({
        type: Actions.EDIT_ACTION_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: Actions.EDIT_ACTION_ERROR,
        payload: error.message,
      });
      showAlert("Failed to Edit query", { type: "error" });
      throw error;
    }
  };

  return {
    openState: showEditActionView,
    payload: editActionModalPayload,
    close,
    open,
    editAction,
  };
}

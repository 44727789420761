import { Typography } from "@mui/material";
import { Header, PageRoot, TableRoot } from "./styled";
import { useTable } from "../hooks/useTable";
import { MaterialReactTable } from "material-react-table";
import { SavedQueryAlert } from "../../savedQueries/ui/components/Alert";
import { useSavedQueryAlert } from "../hooks/useSavedQueryAlert";
import { useGlobalState } from "../hooks/useGlobalState";
import { DeleteModal } from "../../../shared-component/DeleteModal";
import { useDeleteQuery } from "../hooks/useDeleteQuery";
import { SaveQueryView } from "../../../shared-component/AssetTable/component/SavePopover";
import { useEditQuery } from "../hooks/useEditQuery";
import { SaveQueryActionTypes } from "../../../../app/asset-management/config";
import { createActionPayloadTransformer } from "../../../../app/asset-management/createActionPayloadTransformer";
import { CMSTService } from "../../../../services/CMSTService";

export const UI = () => {
  const table = useTable();
  const { openState, closeView, saveAlert, data } = useSavedQueryAlert();
  const { newAlertPayload, deleteQueryPayload } = useGlobalState();
  const {
    closeView: closeDeleteView,
    open: openDeleteViewState,
    deleteQuery,
  } = useDeleteQuery();

  const {
    open: openEditActionState,
    data: editQueryData,
    closeView: closeEditQueryView,
    editQuery,
    anchor,
  } = useEditQuery();

  const actions = {
    edit: editQuery,
    save: CMSTService.getInstance().saveQuery,
  };

  return (
    <PageRoot>
      <Header>
        <Typography variant='pageHeading'>CMST Queries</Typography>
      </Header>
      <TableRoot>
        <MaterialReactTable table={table} />
      </TableRoot>
      <SavedQueryAlert
        open={openState}
        onClose={closeView}
        onSave={saveAlert}
        data={data}
        meta={newAlertPayload}
        payloadTransformer={createActionPayloadTransformer}
      />
      <DeleteModal
        meta={deleteQueryPayload}
        title='Delete Action'
        onDelete={deleteQuery}
        onClose={closeDeleteView}
        open={openDeleteViewState}
      />
      <SaveQueryView
        anchorEl={anchor}
        actions={actions}
        actionType={SaveQueryActionTypes.EDIT}
        open={openEditActionState}
        data={editQueryData}
        onClose={closeEditQueryView}
      />
    </PageRoot>
  );
};

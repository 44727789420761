import { useMemo } from "react";
import { useColumnsState } from "./useColumnsState";
import { usePrimaryTableState } from "./usePrimaryTableState";
import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";
import { DataGroupsSources } from "../../../../components/Grouping/data";
import { useIsGrouping } from "./useIsGrouping";
import { useGroupingTableState } from "./useGroupingTableState";

export function useSearchResultsFieldColumns() {
  const columns = useColumnsState()[0];
  const { meta } = usePrimaryTableState();
  const isGrouping = useIsGrouping();
  const { meta: groupingMeta } = useGroupingTableState();

  return useMemo(() => {
    let cols = meta?.matched_keys ?? [];
    if (isGrouping) {
      cols = groupingMeta?.matched_keys ?? [];
    }
    console.log(cols);
    if (cols && cols?.length) {
      return createKeysToColumnTransformer(cols, columns);
    }
    return [];
  }, [meta, columns, groupingMeta, isGrouping]);
}

function createKeysToColumnTransformer(keys, columns) {
  const checkIsSelected = (optionId) => {
    return columns.some((col) => col.id === optionId);
  };
  return keys.map((value) => {
    const option = {
      value,
      label: value,
      source: DataGroupsSources.PLATFORM,
    };
    option.id = createOptionId(option, "options");
    option.checked = checkIsSelected(option.id);
    return option;
  });
}

import {
  CancelButton,
  DescriptionInput,
  Field,
  FieldLabel,
  NameInput,
  QuerySummaryRoot,
  SaveAsQueryButton,
  SaveAsViewButtonsRoot,
  SaveButton,
  SaveFormRoot,
  SaveMModalHeader,
  SaveModalViewRoot,
  SummaryChip,
  SummaryRoot,
  TitleText,
  ViewBody,
  ViewFooter,
} from "./styled";
import { useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import { createFiltersPayload } from "./utils/createFiltersPayload";
import { createColumnsPayload } from "./utils/createColumnsPayload";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { useAssetTableState } from "../../hooks/useAssetTableState";
import {
  SaveQueryActionTypes,
  SaveQueryViewTypes,
} from "../../../../../app/asset-management/config";
import { useUserInfo } from "../../../../../hooks/useAppUser";
import { formValidator } from "./utils/formValidator";
import { showAlert, showErrorAlert } from "../../../../../app/alert/showAlert";
import { InvalidPayloadError } from "../../../../../app/errors/form-errors/InvalidPayloadError";
import { createGroupPayload } from "./utils/createGroupPayload";

const SaveAsView = Object.freeze({
  updateExistingQuery: "update",
  saveAasNewQuery: "save-as-new",
});

const Component = ({
  data = {},
  actionType = SaveQueryActionTypes.CREATE,
  viewType = SaveQueryViewTypes.SAVE,
  action,
  actions = {},
  saveQueryData,
  payloadTransform,
  actionPayloadTransformer,
  ...props
}) => {
  const { grouping, currentGroupingOption } = useAssetTableState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [currentSaveAsView, setCurrentSaveAsView] = useState(
    SaveAsView.saveAasNewQuery
  );

  const isUpdatingQuery = currentSaveAsView === SaveAsView.updateExistingQuery;

  const actionButtonLabel = isUpdatingQuery ? "Edit" : "Save";

  const checkFieldError = (field) => {
    return errors.find((error) => error.field === field);
  };

  const { username } = useUserInfo();

  const statusColor = data.filters.length ? "#5CB85C" : "#9E9E9E";
  const filtersStatus = data.filters.length ? "active" : "inactive";

  const numOfCols = data.columns.length;

  const handleOnChangeName = (ev) => {
    setName(ev.target.value);
  };

  const handleOnChangeDescription = (ev) => {
    setDescription(ev.target.value);
  };

  const resetStates = () => {
    setName("");
    setDescription("");
    setLoading(false);
    setErrors([]);
    setCurrentSaveAsView(SaveAsView.saveAasNewQuery);
  };

  const getEditActionPayload = () => {
    let payload = {};
    if (isUpdatingQuery && saveQueryData) {
      payload = { ...saveQueryData, ...data, name, description };
    } else {
      payload = {
        ...data,
        name,
        description,
      };
    }
    if (actionPayloadTransformer?.edit) {
      payload = actionPayloadTransformer.edit(payload);
    }
    return payload;
  };

  const createAction = async () => {
    try {
      if (!actions?.save) throw new Error("Invalid Query action");
      setLoading(true);
      setErrors([]);
      const errors = formValidator({
        name,
        description,
      });
      if (errors.length) {
        throw new InvalidPayloadError("Invalid payload", errors);
      }
      const filtersPayload = createFiltersPayload(data.filters);
      const columnsPayload = createColumnsPayload(data.columns);
      const group = createGroupPayload(data.group);
      const payload = {
        name,
        created_by: username,
        description,
        filters: filtersPayload,
        columns: columnsPayload,
        group,
        table_name: data.table,
        search: data.searchKeyword,
      };
      if (grouping.length) {
        const groupingValue = currentGroupingOption.value;
        const group = grouping[0]?.value;
        payload.group = `${group},${groupingValue}`;
      }
      await actions.save?.(payload);
      setLoading(false);
      props?.onClose();
      resetStates();
      showAlert("Query Created Successfully", { type: "success" });
    } catch (error) {
      console.log(error);
      console.log(error.errors);
      if (error.errors) {
        setErrors(error.errors);
        showErrorAlert("Fill all required fields");
      } else {
        const defaultErrorMessage = "Failed to create query";
        showErrorAlert(defaultErrorMessage);
      }
      setLoading(false);
    }
  };

  const validateActions = () => {
    if (!actions) throw new Error("No Actions found");
  };

  const editAction = async () => {
    try {
      if (!actions?.edit) throw new Error("Invalid Query action");

      setLoading(true);
      setErrors([]);
      validateActions();
      const payload = getEditActionPayload();
      const errors = formValidator({
        name,
        description,
      });
      if (errors.length) {
        throw new InvalidPayloadError("Invalid payload", errors);
      }
      await actions.edit?.(payload);
      actions?.onEditQuery?.(payload);
      resetStates();
      props?.onClose();
      showAlert("Query Edited Successfully", { type: "success" });
    } catch (error) {
      console.log(error);
      if (error.errors) {
        setErrors(error.errors);
        showErrorAlert("Fill all required fields");
      } else {
        const error = { field: "global", message: "Failed to Edit" };
        setErrors([error]);
        showErrorAlert(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnSave = (payload) => {
    if (viewType === SaveQueryViewTypes.SAVE) {
      if (actionType === SaveQueryActionTypes.CREATE) {
        createAction(payload);
      } else if (actionType === SaveQueryActionTypes.EDIT) {
        editAction(payload);
      }
    } else {
      if (currentSaveAsView === SaveAsView.updateExistingQuery) {
        return editAction(payload);
      }
      if (currentSaveAsView === SaveAsView.saveAasNewQuery) {
        return createAction(payload);
      }
    }
  };

  const handleOnChangeSaveAsViewType = (viewType) =>
    setCurrentSaveAsView(viewType);

  const handleOnSaveAsNewQuery = () => {
    handleOnChangeSaveAsViewType(SaveAsView.saveAasNewQuery);
    setName("");
    setDescription("");
  };

  const handleOnUpdateExistingQuery = async () => {
    try {
      handleOnChangeSaveAsViewType(SaveAsView.updateExistingQuery);
      setName(saveQueryData?.name);
      setDescription(saveQueryData?.description);
    } catch (error) {}
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
    }
  }, [data]);

  return (
    <SaveModalViewRoot
      {...props}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: 40,
      }}
    >
      <SaveMModalHeader>
        <TitleText>Save Query</TitleText>
      </SaveMModalHeader>
      <QuerySummaryRoot>
        <FieldLabel>Query Summary:</FieldLabel>
        <SummaryRoot>
          <SummaryChip bgColor='#64B5F6'>
            Number of Columns: {numOfCols}
          </SummaryChip>
          <SummaryChip bgColor={statusColor}>
            Filters Status: {filtersStatus}
          </SummaryChip>
        </SummaryRoot>
      </QuerySummaryRoot>
      <ViewBody>
        <ConditionComponent condition={viewType === SaveQueryViewTypes.SAVE_AS}>
          <SaveAsViewButtonsRoot>
            <SaveAsQueryButton
              selected={isUpdatingQuery}
              onClick={handleOnUpdateExistingQuery}
            >
              Update Existing Query
            </SaveAsQueryButton>
            <SaveAsQueryButton
              onClick={handleOnSaveAsNewQuery}
              selected={currentSaveAsView === SaveAsView.saveAasNewQuery}
            >
              Save as New Query
            </SaveAsQueryButton>
          </SaveAsViewButtonsRoot>
        </ConditionComponent>
        <SaveFormRoot>
          <Field>
            <FieldLabel>Query Name *</FieldLabel>
            <NameInput
              error={checkFieldError("name")}
              onChange={handleOnChangeName}
              value={name}
              required
            />
          </Field>
          <Field>
            <FieldLabel>Query Description *</FieldLabel>
            <DescriptionInput
              value={description}
              onChange={handleOnChangeDescription}
              multiline
              required
              error={checkFieldError("description")}
              rows={5}
            />
          </Field>
        </SaveFormRoot>
      </ViewBody>
      <ViewFooter>
        <CancelButton onClick={props.onClose}>Cancel</CancelButton>
        <SaveButton onClick={handleOnSave}>
          <ConditionComponent
            falsyNode={
              <CircularProgress
                sx={{ color: "#00ffb2" }}
                variant='indeterminate'
                size={"20px"}
              />
            }
            condition={!loading}
          >
            {actionButtonLabel}
          </ConditionComponent>
        </SaveButton>
      </ViewFooter>
    </SaveModalViewRoot>
  );
};

export const SaveQueryView = (props) => {
  if (!props.data || !props.data.filters || !props.data.columns) return null;
  return <Component {...props} />;
};

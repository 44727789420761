import { useEffect } from "react";
import { useFetchPrimaryTableData } from "./useFetchPrimaryTableData";
import { useSaveTableView } from "./useSaveTableView";
import { InventoryFilterUrlParser } from "../../../pages/inventory/utils/filterToUrlModule";
import { TableConfigKeys } from "../../../../containers/inventory/AssetsTable/config";
import { InitialState } from "../stateManagement/initialState";
import { useStateDispatch } from "./useStateDispatch";
import { Actions } from "../stateManagement/actions";
import { useFetchGroupingTableData } from "./useFetchGrouingTableData";
import { useAssetTableState } from "./useAssetTableState";
import { createGroupingOptionsFromSchema } from "../utils/createGroupingOptionsFromSchema";
import { checkValueExists } from "../../../../utils/helperMethods/type-guards";
import { CMSTSaveQueryPayloadAdapter } from "../../../../app/asset-management/cmst/adapters/SaveQueryPayloadTransform";
import { useSaveQueryPayload } from "./useSaveQueryPayload";
import { generateRandomStr } from "../../../../utils/helperMethods/generateId";
import { DataFilteringConjunctions } from "../../../../core/data-filtering/conjunctions";
import { getOperatorByValue } from "../../../../core/data-filtering/getOperatorByValue";

function parseOption(option) {
  if (checkValueExists(option.label)) {
    option.label = `${option.label}`;
  }
  if (checkValueExists(option.value)) {
    option.value = `${option.value}`;
  }
  return option;
}

const applyFilterTransforms = (filter) => {
  const conjunctionPayload = DataFilteringConjunctions.AND;
  const operatorPayload = getOperatorByValue(filter.operator.value);
  const { value: fieldValue, source } = filter.field;
  const fieldPayload = {
    ...filter.field,
    label: fieldValue,
  };
  const values = filter.values.map((val) => {
    return { ...val, source };
  });
  const payload = {
    id: generateRandomStr(),
    addToColumns: false,
    conjunction: conjunctionPayload,
    operator: operatorPayload,
    field: fieldPayload,
    values,
    payload: {},
  };
  return payload;
};

export function useInitTable() {
  const fetchPrimaryTableData = useFetchPrimaryTableData();
  const fetchGroupingTableData = useFetchGroupingTableData();
  const dispatch = useStateDispatch();
  const { schema } = useAssetTableState();
  const saveQueryPayload = useSaveQueryPayload();

  const { getIsSharedView, getViewEncodedPayload, getIsRunQuery } =
    useSaveTableView();

  const createSharedViewPayload = (encoding) => {
    const config = InventoryFilterUrlParser.decodeUrlToFilters(encoding);

    const wasGrouping = !!config.grouping?.group;

    const initialState = {
      grouping: [],
      filters: [],
      currentGroupingOption: null,
      currentGroupingOptions: [],
      searchKeyword: config.search,
      primaryTable: {
        paginationConfig: InitialState.primaryTable.paginationConfig,
      },
      groupingTable: {
        paginationConfig: InitialState.primaryTable.paginationConfig,
      },
    };

    if (config.filters?.length) {
      initialState.filters = config.filters.map(applyFilterTransforms);
    }

    if (wasGrouping) {
      const { group, option } = config.grouping;
      const options = createGroupingOptionsFromSchema([group], schema);
      const parsedOption = parseOption(option);
      initialState.grouping = [group];
      initialState.currentGroupingOption = parsedOption;
      initialState.currentGroupingOptions = options;
      initialState.groupingTable.paginationConfig = config.pagination;
    } else {
      initialState.primaryTable.paginationConfig = config.pagination;
    }

    const fetchPayload = createFetchPayloadFromInitialState({
      ...initialState,
      search: initialState.searchKeyword,
    });

    console.log({ initialState });

    return {
      initialState,
      fetchPayload,
    };
  };

  const createFetchPayloadFromInitialState = (initialState) => {
    const { grouping, currentGroupingOption, filters, search } = initialState;
    const payload = {
      filters,
      search,
      pagination: { pageSize: 20, pageIndex: 0 },
      grouping: null,
    };

    if (grouping.length) {
      const group = grouping[0];
      payload.grouping = { group, option: currentGroupingOption };
    }

    return payload;
  };

  const createSavedQueryViewPayload = (payload, schema) => {
    try {
      const adapter = CMSTSaveQueryPayloadAdapter.instance;

      const initialState = {
        grouping: [],
        currentGroupingOption: null,
        currentGroupingOptions: [],
        filters: [],
        searchKeyword: payload.search,
        primaryTable: {
          paginationConfig: InitialState.primaryTable.paginationConfig,
        },
        groupingTable: {
          paginationConfig: InitialState.primaryTable.paginationConfig,
        },
      };

      if (payload.filters) {
        const filters = adapter.transformFiltersToValidOptions(
          payload.filters,
          schema
        );
        initialState.filters = filters;
      }

      if (payload.group) {
        const { option: groupingValue, group } =
          adapter.transformGroupToCMSTOption(payload.group);
        const accessor = (grouping, schema) => {
          const currentGrouping = grouping[0];

          return schema?.[currentGrouping.value]?.values;
        };
        const options = createGroupingOptionsFromSchema(
          [group],
          schema,
          accessor
        );

        initialState.grouping = [group];
        initialState.currentGroupingOption = parseOption(groupingValue);
        initialState.currentGroupingOptions = options;
      }

      const fetchPayload = createFetchPayloadFromInitialState({
        ...initialState,
        search: initialState.searchKeyword,
      });
      return { initialState, fetchPayload };
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInitialTableData = async () => {
    try {
      const viewEncoding = getViewEncodedPayload();
      const isSharedView = getIsSharedView();

      if (isSharedView) {
        const isRunQuery = getIsRunQuery();

        let initialState, config;
        if (isRunQuery && saveQueryPayload) {
          const { fetchPayload, initialState: state } =
            createSavedQueryViewPayload(saveQueryPayload, schema.platform);

          initialState = state;
          config = fetchPayload;
        }
        if (viewEncoding) {
          const { fetchPayload, initialState: encodingState } =
            createSharedViewPayload(viewEncoding);

          initialState = { ...initialState, ...encodingState };
          config = { ...config, ...fetchPayload };
        }

        const wasGrouping = initialState?.grouping?.length;
        dispatch({ type: Actions.INIT_TABLE_STATES, payload: initialState });
        if (wasGrouping) {
          fetchGroupingTableData(config, {
            [TableConfigKeys.SAVE_VIEW]: false,
          });
        } else {
          fetchPrimaryTableData(config, { [TableConfigKeys.SAVE_VIEW]: false });
        }
      } else {
        fetchPrimaryTableData(undefined, {
          [TableConfigKeys.SAVE_VIEW]: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (schema) {
      fetchInitialTableData();
    }
  }, [schema]);
}

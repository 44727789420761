import { AssetManagementConfig } from "../../../../app/asset-management/config";

export const InitialState = Object.freeze({
  primaryTable: {
    data: [],
    meta: {},
    isLoading: false,
    error: false,
    errorPayload: null,
    paginationConfig: {
      pageSize: AssetManagementConfig.DEFAULT_PAGE_SIZE,
      pageIndex: 0,
    },
  },
  groupingTable: {
    data: [],
    meta: {},
    isLoading: false,
    error: false,
    errorPayload: null,
    paginationConfig: {
      pageSize: AssetManagementConfig.DEFAULT_PAGE_SIZE,
      pageIndex: 0,
    },
  },
  saveQueryPayload: null,
  saveQueryModalPayload: {
    open: false,
    isLoading: false,
    error: false,
    errorPayload: null,
  },
  filters: [],
  columns: [],
  grouping: [],
  currentGroupingOptions: [],
  currentGroupingOption: null,
  schema: {},
  searchKeyword: "",
});
